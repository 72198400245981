<template>
	<v-sheet class="dense-inputs v-sheet--offset mx-auto px-3 pb-3" color="gray lighten-3" :key="pageKey">
		<v-row no-gutters class="mt-3" v-if="canSelectSalesColleague">
			<v-col :class="$vuetify.breakpoint.smAndDown ? 'd-flex col-12 flex-row align-center' : (salesColleagueCountries.length > 0 ? 'd-flex col-3 flex-row align-center' : 'd-flex col-2 flex-row align-center')">
				<v-autocomplete
						:items="filteredSalesColleagues"
						:label="$t('message.selectSalesColleague')"
						autocomplete="password"
						dense
						hide-details="auto"
						item-text="Salesperson.name"
						item-value="Salesperson.id"
						required
						style="width: 250px !important;"
						solo
						v-model="dashboardCurrentSalesColleague"
						@change="salesColleagueChanged()"
				>
					<template v-slot:item="data">
						<template>
							<v-list-item-avatar>
								<img :src="data.item.User.photo ? ('data:image/jpeg;base64,' + data.item.User.photo) : '/static/avatars/appic_user.jpg'" alt="avatar" height="35" width="35" class="img-responsive rounded-circle">
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title v-html="purify(data.item.Salesperson.name)"></v-list-item-title>
								<v-list-item-subtitle v-html="purify(data.item.Country.name)"></v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</template>
				</v-autocomplete>
                <v-select
                    :items="salesColleagueCountries"
                    class="ml-3"
                    hide-details="auto"
                    solo
                    v-model="dashboardCurrentCountry"
                    @change="salesCountryChanged()"
                    v-if="salesColleagueCountries.length > 0"
                >
                </v-select>
				<v-btn icon @click="reloadDashboard(); reloading === true" :loading="reloading" class="ml-3"><v-icon large>refresh</v-icon></v-btn>
			</v-col>
		</v-row>
        <v-row no-gutters class="mt-3" v-if="canSelectSalesColleague === false">
            <v-col :class="$vuetify.breakpoint.smAndDown ? 'd-flex col-12 flex-row align-center' : (salesColleagueCountries.length > 0 ? 'd-flex col-3 flex-row align-center' : 'd-flex col-2 flex-row align-center')">
                <v-select
                    :items="salesColleagueCountries"
                    class="ml-3"
                    hide-details="auto"
                    solo
                    v-model="dashboardCurrentCountry"
                    @change="salesCountryChanged()"
                    v-if="salesColleagueCountries.length > 0"
                >
                </v-select>
                <v-btn icon @click="reloadDashboard(); reloading === true" :loading="reloading" class="ml-3"><v-icon large>refresh</v-icon></v-btn>
            </v-col>
        </v-row>
		<template v-if="dashboardCurrentSalesColleague != null">
			<v-row no-gutters class="mt-4">
				<v-col cols="12">
					<v-card elevation="6" class="py-0">
						<v-card-text class="pa-1">
							<div class="text-center black--text font-weight-bold" style="font-size: 1.25em; !important;">{{ $t('message.gmIntake') }}</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-20-pct'">
					<QuarterGrossMargin
						:color="isCurrentQuarter(1) ? 'salmon' : 'light-green'"
						:conditions="conditions"
						:is-current-quarter="isCurrentQuarter(1)"
						:report="'gm-first-quarter'"
						:key="quarterGrossMarginKey1"
					/>
				</v-col>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-20-pct'">
					<QuarterGrossMargin
						:color="isCurrentQuarter(2) ? 'salmon' : 'light-green'"
						:conditions="conditions"
						:is-current-quarter="isCurrentQuarter(2)"
						:report="'gm-second-quarter'"
						:key="quarterGrossMarginKey2"
					/>
				</v-col>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-20-pct'">
					<QuarterGrossMargin
						:color="isCurrentQuarter(3) ? 'salmon' : 'light-green'"
						:conditions="conditions"
						:is-current-quarter="isCurrentQuarter(3)"
						:report="'gm-third-quarter'"
						:key="quarterGrossMarginKey3"
					/>
				</v-col>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-20-pct'">
					<QuarterGrossMargin
						:color="isCurrentQuarter(4) ? 'salmon' : 'light-green'"
						:conditions="conditions"
						:is-current-quarter="isCurrentQuarter(4)"
						:report="'gm-fourth-quarter'"
						:key="quarterGrossMarginKey4"
					/>
				</v-col>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-20-pct'">
					<YearlyGrossMargin
						:color="'cyan'"
						:conditions="conditions"
						:report="'gm-full-year'"
						:key="yearlyGrossMarginKey"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-30-pct'">
					<GrossMarginForecastChart :conditions="conditions" :key="grossMarginForecastKey"/>
				</v-col>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-20-pct'">
					<CurrentMissingGrossMargin :conditions="conditions" :key="missingGrossMarginKey" :style="!$vuetify.breakpoint.smAndDown ? 'margin-right: 12px !important;' : ''"/>
					<GrossMarginExceeded :conditions="conditions" :key="grossMarginExceededKey"/>
				</v-col>
				<v-divider role="presentation" vertical inset v-if="!$vuetify.breakpoint.smAndDown"/>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-50-pct'">
					<CommissionForecastChartAndPayOut :conditions="conditions" :key="commissionForecastKey" :style="!$vuetify.breakpoint.smAndDown ? 'margin-left: 12px !important;' : ''"/>
				</v-col>
			</v-row>
			<v-row>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-80-pct'">
					<DueInvoices :conditions="conditions" :key="dueInvoicesKey"/>
				</v-col>
				<v-col :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-20-pct'">
					<MotivationalQuote :key="motivationalQuoteKey"/>
				</v-col>
			</v-row>
		</template>
		<template v-if="pageLoading && !canSelectSalesColleague">
			<v-row>
				<v-col class="text-center">{{ $t('message.loading') }}</v-col>
			</v-row>
		</template>
	</v-sheet>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
// const BulletSparkline = () => import("Components/Appic/Dashboard/BulletSparkline.vue");
import {getCurrentQuarter, log, numberFormat, purify} from "Helpers/helpers";
import {api} from "Api";
const QuarterGrossMargin = () => import("Components/Appic/Dashboard/QuarterGrossMargin.vue");
const YearlyGrossMargin = () => import("Components/Appic/Dashboard/YearlyGrossMargin.vue");
const CommissionForecastChart = () => import("Components/Appic/Dashboard/CommissionForecastChart.vue");
const CommissionForecastChartAndPayOut = () => import("Components/Appic/Dashboard/CommissionForecastChartAndPayOut.vue");
const CurrentMissingGrossMargin = () => import("Components/Appic/Dashboard/CurrentMissingGrossMargin.vue");
const MaximumCommissionPotential = () => import("Components/Appic/Dashboard/MaximumCommissionPotential.vue");
const CurrentCommission = () => import("Components/Appic/Dashboard/CurrentCommission.vue");
const DueInvoices = () => import("Components/Appic/Dashboard/DueInvoices.vue");
const GrossMarginForecastChart = () => import("Components/Appic/Dashboard/GrossMarginForecastChart.vue");
const GrossMarginExceeded = () => import("Components/Appic/Dashboard/GrossMarginExceeded.vue");
const CommissionPayOut = () => import("Components/Appic/Dashboard/CommissionPayOut.vue");
const MotivationalQuote = () => import("Components/Appic/Dashboard/MotivationalQuote.vue");

export default {
	name: "MyCommission",
	components: {
		QuarterGrossMargin,
		CommissionForecastChart,
		CommissionForecastChartAndPayOut,
		CommissionPayOut,
		DueInvoices,
		CurrentCommission,
		GrossMarginExceeded,
		MaximumCommissionPotential,
		MotivationalQuote,
		CurrentMissingGrossMargin,
		GrossMarginForecastChart,
		// BulletSparkline,
		YearlyGrossMargin
	},
    data() {
		return {
			canSelectSalesColleague: false,
			cmSalesContactIds: [],
            dashboardCurrentCountry: null,
            quarterGrossMarginKey1: 1,
			quarterGrossMarginKey2: 2,
			quarterGrossMarginKey3: 3,
			quarterGrossMarginKey4: 4,
			yearlyGrossMarginKey: 5,
			conditions: [],
			missingGrossMarginKey: 7,
			grossMarginExceededKey: 8,
			grossMarginForecastKey: 9,
			currentCommissionKey: 10,
			maximumCommissionPotentialKey: 11,
			dueInvoicesKey: 12,
			commissionForecastKey: 13,
			commissionPayoutKey: 14,
			motivationalQuoteKey: 15,
			reloading: false,
			pageKey: 0,
			pageLoading: true,
            salesColleagueCountries: []
        }
    },
    computed: {
		...mapFields('runtime',{
			dashboardCurrentSalesColleague: 'dashboardCurrentSalesColleague',
		}),
		...mapGetters('salescolleague', {
			allSalesColleagues: 'allSalesColleagues',
			notSalesColleagues: 'notSalesColleagues',
		}),
		...mapGetters('user',{
			User: 'User'
		}),
		filteredSalesColleagues() {
			if(this.User.role === 'ROLE_COUNTRY_MANAGER'){
				if(this.cmSalesContactIds.length > 0){
					return this.allSalesColleagues.filter(sc => {
						return this.cmSalesContactIds.includes(sc.Salesperson.id)
					})
				} else {
					return []
				}
			} else {
				return this.allSalesColleagues.filter(sc => {
					return !this.notSalesColleagues.includes(sc.Salesperson.id)
				})
			}
		}
    },
    methods: {
		...mapActions('salescolleague', {
			getAllSalesColleagues: 'getAllSalesColleagues',
			getNotSalesColleagues: 'getNotSalesColleagues',
			resetSalesColleagues: 'resetState'
		}),
		formatThisNumber (value,format) {
			return numberFormat(value,format)
		},
		isCurrentQuarter(qtr) {
			const currentQtr = getCurrentQuarter()
			if(qtr === currentQtr) return true
			return false
		},
		purify,
		reloadDashboard() {
			this.quarterGrossMarginKey1 += 1
			this.quarterGrossMarginKey2 += 1
			this.quarterGrossMarginKey3 += 1
			this.quarterGrossMarginKey4 += 1
			this.yearlyGrossMarginKey += 1
			this.missingGrossMarginKey += 1
			this.grossMarginExceededKey += 1
			this.grossMarginForecastKey += 1
			this.currentCommissionKey += 1
			this.maximumCommissionPotentialKey += 1
			this.dueInvoicesKey += 1
			this.commissionForecastKey += 1
			this.commissionPayoutKey += 1
			this.motivationalQuoteKey += 1
			const _this = this
			setTimeout(() => {
				_this.reloading = false
			},5000)
		},
		salesColleagueChanged() {
			this.quarterGrossMarginKey1 += 1
			this.quarterGrossMarginKey2 += 1
			this.quarterGrossMarginKey3 += 1
			this.quarterGrossMarginKey4 += 1
			this.yearlyGrossMarginKey += 1
			this.missingGrossMarginKey += 1
			this.grossMarginExceededKey += 1
			this.grossMarginForecastKey += 1
			this.currentCommissionKey += 1
			this.maximumCommissionPotentialKey += 1
			this.dueInvoicesKey += 1
			this.commissionForecastKey += 1
			this.commissionPayoutKey += 1
			this.motivationalQuoteKey += 1

            // console.log(this.salesColleagueCountries)

            this.dashboardCurrentCountry = null

            const salesColleague = this.filteredSalesColleagues.find(s => parseInt(s.Salesperson.id) === parseInt(this.dashboardCurrentSalesColleague))
            if(salesColleague) {
                const countries = salesColleague.Salesperson.split_country_incentives
                if(countries && countries.length > 0) {
                    this.salesColleagueCountries = [];
                    countries.forEach(c => {
                        const country = {
                            text: c.id,
                            value: c.id
                        }
                        this.salesColleagueCountries.push(country)
                    })
                    this.dashboardCurrentCountry = this.salesColleagueCountries[0]['value'];
                }
            }
        },
        salesCountryChanged() {
            this.quarterGrossMarginKey1 += 1
            this.quarterGrossMarginKey2 += 1
            this.quarterGrossMarginKey3 += 1
            this.quarterGrossMarginKey4 += 1
            this.yearlyGrossMarginKey += 1
            this.missingGrossMarginKey += 1
            this.grossMarginExceededKey += 1
            this.grossMarginForecastKey += 1
            this.currentCommissionKey += 1
            this.maximumCommissionPotentialKey += 1
            this.dueInvoicesKey += 1
            this.commissionForecastKey += 1
            this.commissionPayoutKey += 1
            this.motivationalQuoteKey += 1
        }
    },
	watch: {
		dashboardCurrentSalesColleague(val) {
			if(val != null){
				this.conditions = [
					{
						'field': 'salescontact_id',
						'value': val
					}
				]
			}
            if(this.dashboardCurrentCountry != null) {
                this.conditions.push(
                    {
                        'field': 'country_id',
                        'value': this.dashboardCurrentCountry
                    }
                )
            }
		},
        dashboardCurrentCountry(val){
            if(this.dashboardCurrentSalesColleague != null){
                this.conditions = [
                    {
                        'field': 'salescontact_id',
                        'value': this.dashboardCurrentSalesColleague
                    }
                ]
            }
            if(val != null) {
                this.conditions.push(
                    {
                        'field': 'country_id',
                        'value': val
                    }
                )
            }
        }
	},
    created() {
		// this.resetSalesColleagues()
		if(this.notSalesColleagues.length === 0){
			this.getNotSalesColleagues()
				.then(()=>{})
				.catch((error)=>{
					this.$toast.error( error,
						{
							classes: ['icon-float-left'],
							icon: 'error_outline'
						}
					)
				})
		}
		if(this.allSalesColleagues.length === 0){
			this.getAllSalesColleagues()
				.then(() => {
					switch (this.User.role){
						case 'ROLE_SUPERADMIN':
						case 'ROLE_ADMINISTRATOR':
							this.canSelectSalesColleague = true
							break;
						case 'ROLE_COUNTRY_MANAGER':
							this.canSelectSalesColleague = true
							const thisSalesContact = this.allSalesColleagues.find(s => parseInt(s.Salesperson.userid) === parseInt(this.User.email))
							if (thisSalesContact) {
								if(thisSalesContact.Salesperson.cm_salescontact_ids.length > 0) {
									this.cmSalesContactIds = thisSalesContact.Salesperson.cm_salescontact_ids
								}
								if(!this.notSalesColleagues.includes(thisSalesContact.Salesperson.id)) {
									this.cmSalesContactIds.push(thisSalesContact.Salesperson.id)
									this.dashboardCurrentSalesColleague = thisSalesContact.Salesperson.id
								}
							}
							break;
						case 'ROLE_SALES':
							//get salescontact_id
							const salesContact = this.allSalesColleagues.find(s => s.Salesperson.userid === this.User.email)
                            if (salesContact) {
								//set current salescontact_id
                                this.dashboardCurrentSalesColleague = salesContact.Salesperson.id
							}
							break;
					}
					if(this.dashboardCurrentSalesColleague != null){
						this.conditions = [
							{
								'field': 'salescontact_id',
								'value': this.dashboardCurrentSalesColleague
							}
						]

                        //set dashboard countries
                        const salesColleague = this.filteredSalesColleagues.find(s => s.Salesperson.userid === this.dashboardCurrentSalesColleague)
                        if(salesColleague) {
                            const countries = salesColleague.Salesperson.split_country_incentives
                            if(countries && countries.length > 0) {
                                this.salesColleagueCountries = [];
                                countries.forEach(c => {
                                    const country = {
                                        text: c.id,
                                        value: c.id
                                    }
                                    this.salesColleagueCountries.push(country)
                                })
                                this.dashboardCurrentCountry = this.salesColleagueCountries[0]['value'];
                                this.conditions.push(
                                    {
                                        'field': 'country_id',
                                        'value': this.dashboardCurrentCountry
                                    }
                                )
                            }
                        }
					}
				})
				.catch((error) => {
					this.$toast.error( error,
						{
							classes: ['icon-float-left'],
							icon: 'error_outline'
						}
					)
				})
		} else {
			switch (this.User.role){
				case 'ROLE_SUPERADMIN':
				case 'ROLE_ADMINISTRATOR':
					this.canSelectSalesColleague = true
					break;
				case 'ROLE_COUNTRY_MANAGER':
					this.canSelectSalesColleague = true
					const thisSalesContact = this.allSalesColleagues.find(s => s.Salesperson.userid === this.User.email)
					if (thisSalesContact) {
						if(thisSalesContact.Salesperson.cm_salescontact_ids.length > 0) {
							this.cmSalesContactIds = thisSalesContact.Salesperson.cm_salescontact_ids
						}
						if(!this.notSalesColleagues.includes(thisSalesContact.Salesperson.id)) {
							this.cmSalesContactIds.push(thisSalesContact.Salesperson.id)
							this.dashboardCurrentSalesColleague = thisSalesContact.Salesperson.id
						}
					}
					break;
				case 'ROLE_SALES':
					//get salescontact_id
					const salesContact = this.allSalesColleagues.find(s => s.Salesperson.userid === this.User.email)
					if (salesContact) {
						//set current salescontact_id
						this.dashboardCurrentSalesColleague = salesContact.Salesperson.id
					}
					break;
			}
			if(this.dashboardCurrentSalesColleague != null){
				this.conditions = [
					{
						'field': 'salescontact_id',
						'value': this.dashboardCurrentSalesColleague
					}
				]

                //set dashboard countries
                const salesColleague = this.filteredSalesColleagues.find(s => s.Salesperson.id === this.dashboardCurrentSalesColleague)
                if(salesColleague) {
                    const countries = salesColleague.Salesperson.split_country_incentives
                    if(countries && countries.length > 0) {
                        this.salesColleagueCountries = [];
                        countries.forEach(c => {
                            const country = {
                                text: c.id,
                                value: c.id
                            }
                            this.salesColleagueCountries.push(country)
                        })
                        this.dashboardCurrentCountry = this.salesColleagueCountries[0]['value'];
                        this.conditions.push(
                            {
                                'field': 'country_id',
                                'value': this.dashboardCurrentCountry
                            }
                        )
                    }
                }
			}
		}
	},
	mounted() {
		this.reloading = false
		this.pageLoading = false
		this.$emit('mounted')
	}
}
</script>

<style>
.col-20-pct {
	max-width: 20% !important;
}
.col-30-pct {
	max-width: 30% !important;
}
.col-37-5-pct {
	max-width: 37.5% !important;
}
.col-80-pct {
	max-width: 80% !important;
}
.col-50-pct {
	max-width: 50% !important;
}
.v-divider--vertical {
	border-width: 2px !important;
	border-color: darkgray !important;
	margin-top: 11px !important;
	margin-bottom: 11px !important;
}
</style>